@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;

$PHONE_PADDING: 3; // 0-599
$TABLET_AND_SMALL_DESKTOP_PADDING: 5; // 600-1279
$BIG_DESKTOP_PADDING: 6; // 1280+

.root {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

.withLeftPadding {
  padding-left: u.spacing($PHONE_PADDING);
  @include mq.forTabletLandscape {
    padding-left: u.spacing($TABLET_AND_SMALL_DESKTOP_PADDING);
  }
  @include mq.forDesktopMidUp {
    padding-left: u.spacing($BIG_DESKTOP_PADDING);
  }
}

.withRightPadding {
  padding-right: u.spacing($PHONE_PADDING);
  @include mq.forTabletLandscape {
    padding-right: u.spacing($TABLET_AND_SMALL_DESKTOP_PADDING);
  }
  @include mq.forDesktopMidUp {
    padding-right: u.spacing($BIG_DESKTOP_PADDING);
  }
}
