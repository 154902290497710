.root {
  background-color: var(--c-white);
}

.circularRoot {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.textWrapper {
  margin-top: 11px;
}
