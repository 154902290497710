@use 'ui-kit/theme/media' as mq;

.root {
  color: var(--text-link-color);
  cursor: pointer;
  text-decoration: underline;
  font-size: var(--text-link-font-size);
  &:hover {
    text-decoration: underline;
  }
  @include mq.forUpToTablet {
    font-size: var(--text-link-font-size-mobile);
  }
}

.notUnderlined {
  text-decoration: none;
}
