.root {
  height: 100vh;
  width: 100%;
}

.secondHeading {
  margin-top: 50px;
}

.linksWrapper {
  height: 200px;
}
