@use 'ui-kit/theme/units' as u;

.root {
  font-size: 16px;
  font-weight: bold;
  text-transform: none;
  min-width: auto;
  text-decoration: none;
  background-color: transparent;
  color: var(--text-button-color);
  padding: var(--text-button-padding);
  &:hover {
    color: var(--text-button-hover-color);
    background-color: transparent;
  }
  &:active {
    color: var(--text-button-active-color);
    background-color: transparent;
  }
  &:disabled {
    color: var(--c-gray700);
  }
  &:disabled svg {
    filter: grayscale(1);
  }
}

.underline {
  text-decoration: underline;
}

.icon {
  display: inline-flex;
  margin-right: 0;
}

.marginRight {
  margin-right: u.spacing(1);
}

.dot {
  width: 8px;
  height: 8px;
  background-color: var(--c-intenseRed);
  border-radius: 99px;
  position: absolute;
  top: 7px;
  right: 7px;
}
