@use 'ui-kit/theme/units' as u;

.arrow {
  color: var(--c-white);
  font-size: 10px;
}

.darkArrow {
  color: var(--c-black);
}

.tooltip {
  background-color: var(--c-white);
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  border-radius: 0;
  padding: u.spacing(2);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.08);
  max-width: var(--tooltip-max-width);
}

.darkTooltip {
  background-color: var(--c-black);
  color: var(--c-white);
}

.roundedTooltip {
  border-radius: 4px;
}
