/* 
* THIS FILE IS AUTOGENERATED, DO NOT MODIFY IT.
* File: _media.scss, Generated by: create-sass-theme.js
*/

$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-md: 960px;
$breakpoint-lg: 1280px;
$breakpoint-xl: 1920px;

@mixin forPhone {
  @media (max-width: 599.95px) {
    @content;
  }
}

@mixin forUpToTablet {
  @media (max-width: 959.95px) {
    @content;
  }
}

@mixin forUpToTabletLandscape {
  @media (max-width: 1279.95px) {
    @content;
  }
}

@mixin forUpToHD {
  @media (max-width: 1919.95px) {
    @content;
  }
}

@mixin forTablet {
  @media (min-width: 600px) and (max-width: 959.95px) {
    @content;
  }
}

@mixin forTabletLandscape {
  @media (min-width: 600px) and (max-width: 1279.95px) {
    @content;
  }
}

@mixin forDesktopSmall {
  @media (min-width: 960px) and (max-width: 1279.95px) {
    @content;
  }
}

@mixin forDesktopMid {
  @media (min-width: 1280px) and (max-width: 1439.95px) {
    @content;
  }
}

@mixin forDesktopWide {
  @media (min-width: 1440px) and (max-width: 2559.95px) {
    @content;
  }
}

@mixin forTabletUp {
  @media (min-width: 600px) {
    @content;
  }
}

@mixin forDesktopSmallUp {
  @media (min-width: 960px) {
    @content;
  }
}

@mixin forDesktopMidUp {
  @media (min-width: 1280px) {
    @content;
  }
}

@mixin forDesktopHDUp {
  @media (min-width: 1920px) {
    @content;
  }
}

@mixin forDesktopUltraWideUp {
  @media (min-width: 2560px) {
    @content;
  }
}
